<template>
  <el-dialog width="80%" :title="!dataForm.id ? '新增配置' : '编辑配置'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="beforeSubmit()">


      <el-form-item label="prompt类型选择：" prop="useCustomPrompt">
        <el-radio-group v-model="dataForm.useCustomPrompt" @input="promptTypeChange">
          <el-radio :label="true">自定义prompt</el-radio>
          <el-radio :label="false">模块化prompt</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-tabs v-model="currentLevel" type="border-card">

        <el-tab-pane v-for="(item, index) in dataMap" :key="index" :label="item.label" :name="item.level">

          <h3 class="title">1. 亲密度区间</h3>
          <el-form-item label="亲密值>=：" :prop="'levelInfoList.' + item.dataIndex + '.intimacyValueThreshold'"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="dataForm.levelInfoList[item.dataIndex].intimacyValueThreshold"
              placeholder="亲密值"></el-input>
          </el-form-item>

          <el-form-item label="亲密度增加上限/日：" :prop="'levelInfoList.' + item.dataIndex + '.intimacyValueLimitPerDay'"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="dataForm.levelInfoList[item.dataIndex].intimacyValueLimitPerDay"></el-input>
          </el-form-item>

          <el-form-item label="涉及角色" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-select v-model="dataForm.allUidList" clearable multiple
              :multiple-limit="dataForm.levelInfoList[item.dataIndex].useCustomPrompt ? 1 : 0" placeholder="涉及角色">
              <el-option v-for="item in virtualUser" :key="item.uid" :label="item.nickname" :value="String(item.uid)">
              </el-option>
            </el-select>
          </el-form-item>


          <h3 v-if="!dataForm.useCustomPrompt" class="title">2. 模块化prompt输入</h3>
          <h3 v-if="dataForm.useCustomPrompt" class="title">2. 自定义prompt输入</h3>

          <el-tabs type="border-card">
            <el-tab-pane label="阿拉伯语">
              <div v-if="!dataForm.useCustomPrompt">
                <el-form-item label="称呼用户：" :prop="'levelInfoList.' + item.dataIndex + '.levelItem.addressAr'">
                  <el-input v-model="dataForm.levelInfoList[item.dataIndex].levelItem.addressAr"></el-input>
                </el-form-item>

                <el-form-item label="说话风格：" :prop="'levelInfoList.' + item.dataIndex + '.levelItem.conversationStyleAr'">
                  <el-input v-model="dataForm.levelInfoList[item.dataIndex].levelItem.conversationStyleAr"></el-input>
                </el-form-item>

              </div>

              <div v-if="dataForm.useCustomPrompt">
                <el-form-item style="width: 100%" label="自定义prompt"
                  :prop="'levelInfoList.' + item.dataIndex + '.customPromptAr'">

                  <el-input rows="6" style="width: 600px" type="textarea"
                    v-model="dataForm.levelInfoList[item.dataIndex].customPromptAr"></el-input>
                </el-form-item>
              </div>

            </el-tab-pane>

            <el-tab-pane label="英语">
              <div v-if="!dataForm.useCustomPrompt">
                <el-form-item label="称呼用户：" :prop="'levelInfoList.' + item.dataIndex + '.levelItem.address'">
                  <el-input v-model="dataForm.levelInfoList[item.dataIndex].levelItem.address"></el-input>
                </el-form-item>

                <el-form-item label="说话风格：" :prop="'levelInfoList.' + item.dataIndex + '.levelItem.conversationStyle'">
                  <el-input v-model="dataForm.levelInfoList[item.dataIndex].levelItem.conversationStyle"></el-input>
                </el-form-item>

              </div>

              <div v-if="dataForm.useCustomPrompt">
                <el-form-item style="width: 100%" label="自定义prompt"
                  :prop="'levelInfoList.' + item.dataIndex + '.customPrompt'">

                  <el-input rows="6" style="width: 600px" type="textarea"
                    v-model="dataForm.levelInfoList[item.dataIndex].customPrompt"></el-input>
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>

          <h3 class="title">3. 对用户关注度</h3>
          <el-row>
            <el-col :span="8">
              <el-form-item style="width: 100%" label="主动向用户打招呼："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.proactivelyGreeting'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-radio-group v-model="dataForm.levelInfoList[item.dataIndex].levelItem.proactivelyGreeting">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item style="width: 100%" label="主动完结本次对话："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.proactivelyEndConversation'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-radio-group v-model="dataForm.levelInfoList[item.dataIndex].levelItem.proactivelyEndConversation">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>



            <el-col :span="8">
              <el-form-item style="width: 100%" label="主动关注用户："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.followUserBack'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-radio-group v-model="dataForm.levelInfoList[item.dataIndex].levelItem.followUserBack">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="8">
              <el-form-item label="主动向用户送礼物："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.proactivelyGivingPresent'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-radio-group v-model="dataForm.levelInfoList[item.dataIndex].levelItem.proactivelyGivingPresent">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item v-if="dataForm.levelInfoList[item.dataIndex].levelItem.proactivelyGivingPresent"
                label="送给用户礼物：" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-select v-model="dataForm.levelInfoList[item.dataIndex].levelItem.giftIdList" clearable multiple
                  placeholder="请选择礼物">
                  <el-option v-for="item in gifts" :key="item.id" :label="item.name" :value="item.id">
                    <slot>
                      <span>{{ item.id }} （{{ item.name }}）</span>
                    </slot>
                  </el-option>
                </el-select>
              </el-form-item>

            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item style="width: 100%" label="为用户记录日记："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.trackUserConversion'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-radio-group v-model="dataForm.levelInfoList[item.dataIndex].levelItem.trackUserConversion">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item style="width: 100%" label="记忆周期上限（天）："
                :prop="'levelInfoList.' + item.dataIndex + '.levelItem.memoryLimit'"
                :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-input v-model="dataForm.levelInfoList[item.dataIndex].levelItem.memoryLimit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-tab-pane>


      </el-tabs>


    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="beforeSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const commonForm = {
  uidList: [],
  configLevelId: 0,
  intimacyValueThreshold: '',
  intimacyValueLimitPerDay: '',
  useCustomPrompt: true,
  customPrompt: '',
  customPromptAr: '',
  levelItem: {
    address: '',
    addressAr: '',
    conversationStyle: '',
    conversationStyleAr: '',
    responseTime: '',
    proactivelyGreeting: false,
    proactivelyEndConversation: false,
    followUserBack: false,
    proactivelyGivingPresent: false,
    memoryLimit: '',
    trackUserConversion: false,
    giftIdList: []
  }
}

const sourceDataForm = {
  allUidList: [],
  currentConfigId: 0,
  useCustomPrompt: true,
  levelInfoList: [
    {
      level: 0,
      ...commonForm
    },
    {
      level: 1,
      ...commonForm
    },
    {
      level: 2,
      ...commonForm
    },
    {
      level: 3,
      ...commonForm
    },
    {
      level: 4,
      ...commonForm
    },
    {
      level: 5,
      ...commonForm
    }
  ]
}
export default {
  data() {
    return {
      // 默认进来是陌生朋友
      currentLevel: 0,
      visible: false,
      virtualUser: [],
      gifts: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataMap: [
        {
          level: "0",
          label: 'L0',
          dataIndex: 0,
        },
        {
          level: "1",
          label: 'L1',
          dataIndex: 1,
        },
        {
          level: "2",
          label: 'L2',
          dataIndex: 2,
        },
        {
          level: "3",
          label: 'L3',
          dataIndex: 3,
        },
        {
          level: "4",
          label: 'L4',
          dataIndex: 4,
        },
        {
          level: "5",
          label: 'L5',
          dataIndex: 5,
        }
      ],
    }
  },
  async created() {
    this.virtualUser = await this.$store.dispatch("GetVirtualUser", {});
    this.$http({
      url: this.$http.adornUrl(`/admin/gift/all`),
      method: 'get'
    }).then(({ data }) => {
      if (data && data.code === 0) {
        this.gifts = data.result
      } else {
        this.$message.error(data.msg)
      }
    })
  },
  methods: {
    init(id) {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.dataForm.currentConfigId = id || 0
      if (this.dataForm.currentConfigId === 0) {
        // 新增模式
        this.visible = true
        return;
      }
      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.$http({
        url: this.$http.adornUrl(`/admin/character-config/info/${this.dataForm.currentConfigId}`),
        method: 'get'
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.visible = true

          const sortedItem = data.result.levelInfoList.slice().sort((a, b) => a.level - b.level);
          this.dataForm = {
            ...data.result,
            levelInfoList: sortedItem.reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, [])
          };
          const uidList = [...this.dataForm.levelInfoList[0].uidList]
          for (let i = this.dataForm.levelInfoList.length; i < 6; i++) {
            this.dataForm.levelInfoList.push({
              level: i,
              ...commonForm,
              uidList: uidList,
            })
          }
          this.dataForm.currentConfigId = data.result.id
          console.log(this.dataForm)
        }
      })
    },
    beforeSubmit() {
      if (this.dataForm.id) {
        this.$confirm('确认？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataFormSubmit();
        })
      } else {
        this.dataFormSubmit();
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.levelInfoList.forEach(item => {
        item.uidList = [...this.dataForm.allUidList]
      })
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/admin/character-config/save'),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    promptTypeChange(event) {
      // 把选择的用户都清空
      this.dataForm.levelInfoList.forEach(item => {
        item.uidList = []
        item.useCustomPrompt = event
      })
    }
  }
}
</script>

<style>
.title {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>